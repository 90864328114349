<template>
<div>
    <b-container>
        <div class='main-loader' v-if='isLoading'></div>
        <form-wizard ref="checkoutWizard" color="#212121" :title="null" :subtitle="null" finish-button-text="Submit" back-button-text="Previous" hide-buttons class="checkout-form-wizard steps-transparent mt-1 mt-md-2 h-100vh">
            <!-- account detail tab -->
            <tab-content title="Cart" icon="feather icon-shopping-cart">

                <div class="list-view product-checkout mt-0">

                    <!-- Products List -->
                    <div class="checkout-items">

                        <b-card>
                            <!-- media -->
                            <b-media no-body>
                                <b-media-aside>
                                    <b-link>
                                        <b-avatar rounded :src="userData.profile_url" :text="avatarText(userData.full_name)" variant="light-primary" size="80px" />

                                    </b-link>
                                    <!--/ avatar -->
                                </b-media-aside>
                                <b-media-body class="mt-0 ml-75">
                                    <h4>{{ userData.full_name }}</h4>
                                    <p class="mb-25"><span class="font-weight-bolder">User ID:</span> <span class="ml-50">{{ userData.user_id }}</span></p>
                                    <p class="mb-25"><span class="font-weight-bolder">User Role:</span> <span class="ml-50 text-danger">{{ userData.user_role.toUpperCase() }}</span></p>
                                    <p class="mb-25"><span class="font-weight-bolder">E-mail Address:</span> <span class="ml-50">{{userData.email}}</span></p>
                                    <p class="mb-0"><span class="font-weight-bolder">Status:</span> <span class="ml-50 text-success"> {{ userData.user_status.toUpperCase() }}</span></p>
                                  

                                </b-media-body>
                            </b-media>
                            <!--/ media -->

                        </b-card>

                        <b-card v-for="item in cartData" :key="item.text" class="ecommerce-card" no-body>

                            <!-- Product Image -->
                            <div class="item-img p-5">
                                <b-link>
                                    <b-img v-if='item.membership_type_image_url ==null' :src="require('@/assets/images/illustration/Pot3.svg')" class="image" alt="" />
                                    <b-img v-else :src="item.membership_type_image_url" class="membership-image mb-2" />

                                </b-link>
                            </div>

                            <!-- Product Details: Card Body -->
                            <b-card-body class="p-5">
                                <div class="my-auto">
                                    <div class="item-name">
                                        <h4 class="mb-75">
                                            <b-link class="text-body">
                                                {{ item.membership_type_name }}
                                            </b-link>
                                        </h4>
                                    </div>
                                    <p class="mb-0">{{ item.membership_type_short_description }}</p>
                                    <b-list-group class="list-group-circle text-left">
                                        <b-list-group-item v-for="(items, index) in benefits" :key="index">
                                            {{items.benefit_description}}
                                        </b-list-group-item>
                                    </b-list-group>
                                </div>
                            </b-card-body>

                            <!-- Product Options/Actions -->
                            <div class="item-options p-5">
                                <div class="item-wrapper m-auto">
                                    <div class="">

                                        <h4 class="up-price text-uppercase text-secondary" v-if='item.extra_amount==0'>
                                            ${{
                          item.is_discount_price == 1
                            ? parseFloat(item.membership_discount_price_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : parseFloat(item.member_price_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                                        </h4>
                                        <h4 class="up-price text-uppercase" v-if='item.extra_amount==1'>
                                            <span>${{item.payment_amount}}</span>
                                            <span class="text-danger font-weight-normal ml-50 line-through">
                                                ${{
                          item.is_discount_price == 1
                            ? parseFloat(item.membership_discount_price_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : parseFloat(item.member_price_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                                            </span>

                                        </h4>
                                        <span class="text-muted" v-if="item.membership_type == 1">for {{item.membership_term}} month</span>
                                        <span class="text-muted" v-else>for {{item.membership_term}} year</span>
                                      
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>

                    <!-- Checkout Options -->
                    <div class="checkout-options">
                        <b-card>
                      
                            <div class="price-details">
                                <h6 class="price-title">
                                    Price Details
                                </h6>
                                <ul class="list-unstyled">
                                    <li class="price-detail" v-if='extra_amount==1'>
                                        <div class="detail-title">Sub Total</div>
                                        <div class="detail-amt text-primary">
                                            ${{
                            amountDetails.is_discount_price == 1
                              ? parseFloat(amountDetails.membership_discount_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : parseFloat(amountDetails.membership_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>

                                    <li class="price-detail" v-if='extra_amount==1'>
                                        <div class="detail-title">Discount</div>
                                        <div class="detail-amt text-primary">
                                            ${{
                                            amountDetails.consumed_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>

                                    <li class="price-detail" v-if='extra_amount==0'>
                                        <div class="detail-title">Total Price</div>
                                        <div class="detail-amt font-weight-bolder">
                                            ${{
                            amountDetails.is_discount_price == 1
                              ? parseFloat(amountDetails.membership_discount_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : parseFloat(amountDetails.membership_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>

                                    <li class="price-detail" v-if='extra_amount==1'>
                                        <div class="detail-title">Total Price</div>
                                        <div class="detail-amt font-weight-bolder">
                                            ${{
                                              parseFloat(amountDetails.toatl_price_with_discount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>

                                    <li class="price-detail" v-if='extra_amount==0'>
                                        <div class="detail-title">Tax</div>
                                        <div class="detail-amt discount-amt font-weight-bolder">
                                            ${{
                            amountDetails.is_discount_price == 1
                              ? parseFloat(amountDetails.membership_discount_price_gst_amonut).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : parseFloat(amountDetails.membership_price_gst_amonut).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>
                                    <li class="price-detail" v-if='extra_amount==1'>
                                        <div class="detail-title">Tax</div>
                                        <div class="detail-amt discount-amt font-weight-bolder">
                                            ${{
                            parseFloat(amountDetails.gst_payment_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>
                                </ul>
                                <hr>
                                <li class="price-detail" v-if='extra_amount==0'>
                                    <div class="detail-title">Total</div>
                                    <div class="detail-amt discount-amt text-secondary">
                                        ${{

                            amountDetails.is_discount_price == 1
                              ? parseFloat(amountDetails.membership_discount_price_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : parseFloat(amountDetails.member_price_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                        }}
                                    </div>
                                </li>

                                <li class="price-detail" v-if='extra_amount==1'>
                                    <div class="detail-title">Total</div>
                                    <div class="detail-amt discount-amt font-weight-bolder text-success">
                                        ${{

                            parseFloat(amountDetails.payment_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                        }}
                                    </div>
                                </li>

                                <b-button variant="secondary" block @click="validation()">
                                    Continue
                                </b-button>
                            </div>

                        </b-card>
                    </div>
                </div>

            </tab-content>

            <!-- social link -->
            <tab-content title="Payment" icon="feather icon-credit-card">
                <div class="list-view product-checkout">

                    <!-- Left Card -->
                    <b-card no-body>
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>Payment Options</b-card-title>
                       
                        </b-card-header>
                        <b-card-body>
                  

                            <!-- Radios -->
                            <b-form-group>
                                <b-form-radio v-model="paymentMethod" value="newcard" checked="john-doe-debit-card">
                                    Credit/debit card
                                </b-form-radio>
                                <div class="
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-start
                    my-1
                  ">
                                    <stripe-element-card id='card' ref="elementsRef" :pk="publishableKey" :amount="amount" locale="en" name="stripeElements" @token="tokenCreated" @loading="loading = $event" @element-change='enableSubmit($event)'></stripe-element-card>

                                </div>

                            </b-form-group>

                        </b-card-body>
                    </b-card>

                    <!-- Right Card -->
                    <div class="amount-payable checkout-options">
                        <b-card title="Price Details">

                            <ul class="list-unstyled price-details">
                                <ul class="list-unstyled">
                                    <li class="price-detail" v-if='extra_amount==1'>
                                        <div class="detail-title">Sub Total</div>
                                        <div class="detail-amt font-weight-bolder">
                                            ${{
                            amountDetails.is_discount_price == 1
                              ? parseFloat(amountDetails.membership_discount_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : parseFloat(amountDetails.membership_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>

                                    <li class="price-detail" v-if='extra_amount==1'>
                                        <div class="detail-title">Discount</div>
                                        <div class="detail-amt font-weight-bolder">
                                            ${{
                                            parseFloat(amountDetails.consumed_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>

                                    <li class="price-detail" v-if='extra_amount==0'>
                                        <div class="detail-title">Total Price</div>
                                        <div class="detail-amt font-weight-bolder">
                                            ${{
                            amountDetails.is_discount_price == 1
                              ? parseFloat(amountDetails.membership_discount_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : parseFloat(amountDetails.membership_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>

                                    <li class="price-detail" v-if='extra_amount==1'>
                                        <div class="detail-title">Total Price</div>
                                        <div class="detail-amt font-weight-bolder">
                                            ${{
                                              parseFloat(amountDetails.toatl_price_with_discount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>

                                    <li class="price-detail" v-if='extra_amount==0'>
                                        <div class="detail-title">Tax</div>
                                        <div class="detail-amt discount-amt font-weight-bolder">
                                            ${{
                            amountDetails.is_discount_price == 1
                              ? parseFloat(amountDetails.membership_discount_price_gst_amonut).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : parseFloat(amountDetails.membership_price_gst_amonut).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>
                                    <li class="price-detail" v-if='extra_amount==1'>
                                        <div class="detail-title">Tax</div>
                                        <div class="detail-amt discount-amt font-weight-bolder">
                                            ${{
                            parseFloat(amountDetails.gst_payment_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                                        </div>
                                    </li>
                                </ul>
                                <hr />
                                <li class="price-detail" v-if='extra_amount==0'>
                                    <div class="detail-title">Total</div>
                                    <div class="detail-amt discount-amt font-weight-bolder text-secondary">
                                        ${{

                            amountDetails.is_discount_price == 1
                              ? parseFloat(amountDetails.membership_discount_price_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : parseFloat(amountDetails.member_price_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                        }}
                                    </div>
                                </li>

                                <li class="price-detail" v-if='extra_amount==1'>
                                    <div class="detail-title">Total</div>
                                    <div class="detail-amt discount-amt font-weight-bolder text-secondary">
                                        ${{

                            parseFloat(amountDetails.payment_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                        }}
                                    </div>
                                </li>
                            </ul>
                            <b-button variant="secondary" block @click.once="submit()" :disabled='isDisabled'>
                                Place Order
                            </b-button>
                        </b-card>
                    </div>
                </div>

                <div id='footer'></div>

            </tab-content>
        </form-wizard>
    </b-container>
 
</div>
</template>

<script>
import {
    BContainer,
    BRow,
    BCol,
    BFormSpinbutton,
    BFormRadio,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BMediaBody,
} from 'bootstrap-vue'
import {
    FormWizard,
    TabContent
} from 'vue-form-wizard'
import {
    ref
} from '@vue/composition-api'
import {
    StripeElementCard
} from "@vue-stripe/vue-stripe";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSimpleSuggest from "vue-simple-suggest";
import 'vue-simple-suggest/dist/styles.css'
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {
    avatarText
} from '@core/utils/filter'

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BFormSpinbutton,
        BFormRadio,
        // 3rd Party
        FormWizard,
        TabContent,
        VueSimpleSuggest,

        ToastificationContent,
        VueGoogleAutocomplete,
        StripeElementCard,
        BListGroup,
        BListGroupItem,
        BMedia,
        BMediaAside,
        BMediaBody,

    },
    data() {
        return {
            membership_gst: "",
            userData: '',
            userData1: '',

            total_amount: "",
            // value:'',
            catSuggest: [],
            Comments: "",
            amount: 1000,
            
            amountDetails: [],
            isLoading: false,
            fullPage: true,
            loader: "bars",
            token: null,
            charge: null,
            paymentMethod: "newcard",
            isInvoice: false,
            // card_list: [],
            cartData: [],
            full_name: '',
            user_details: [],
            address: "",
            phone_number: '',
            email: '',
            date_issued: '',
            due_date: '',
            invoiceDescription: [],
            tenant_details: [],
            image_url: "",
            tenant_address: '',
            tenant_work: '',
            tenant_contact: '',
            tenant_name: '',

            address: '',
            unit_no: "",
            street_name: '',
            street_number: "",
            suburb: "",
            country: "",
            city: "",
            state: "",
            pin_code: "",
            address_type: 1,
            main_address_type: 1,
            user_role: '',
            fields: [{
                    key: "membership_type_name",
                    label: "Membership",
                },
                {
                    key: "membership_type_code",
                    label: 'Membership Code'

                }, {
                    key: 'membership_price',
                    label: 'Amount',
                    sortable: true
                }
            ],
            table_details: [],

            publishableKey: process.env.VUE_APP_TEST_STRIPE_KEY,
          
            stripe_account: '',

            loading: false,
            file: '',
            tenant_term: "",
            organisation_mobile_phone: '',
            organisation_telephone: '',
            client_address_main: "",
            client_unit_no_main: "",
            client_street_name_main: "",
            client_street_number_main: "",
            client_suburb_main: "",
            client_country_main: "",
            client_city_main: "",
            client_state_main: "",
            client_pin_code_main: "",
            client_address_type_main: 1,
            client_main_address_type_main: 1,

            client_address_ship: '',
            client_unit_no_ship: '',
            client_street_name_ship: '',
            client_street_number_ship: '',
            client_suburb_ship: '',
            client_country_ship: '',
            client_city_ship: '',
            client_state_ship: '',
            client_pin_code_ship: '',
            client_address_type_ship: 2,
            client_main_address_type_ship: 0,
            user_details2: [],
            gst_in_percentage: '',
            extra_amount: '',
            stripe_customer_id: '',
            benefits: [],
            isDisabled: true,
            payment_date: '',
            user_id: '',
            gst:'',
        
            token:''

        }
    },

    created() {
        this.getTenantDetailsById();
        this.getUserDetailsByID();
        this.getIteminCart();

    },

    methods: {

        // Checking if the stripe card  is valid and if it is, it enables the submit button.
        enableSubmit(val) {

            if (val.complete == true) {
                this.isDisabled = false
            } else {
                this.isDisabled = true
            }

        },
       
        validation() {

            // const walletAccounts = localStorage.getItem("BriksStoreAppWallet");
            if (this.Selected == "" && this.Selected1 == "") {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please select address",

                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else {
                this.$refs.checkoutWizard.nextTab();
            }

        },
        async getIteminCart() {
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;

            if (tenant_id == null) {
                const url = window.location.href
                this.gettenantDomain();
            } else {

                var myHeaders = new Headers();

                const userData = localStorage.getItem("userdetails")

                if (userData == null || undefined) {
                    this.user_id = null
                } else {
                    this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                    this.gst=JSON.parse(localStorage.getItem("userdetails")).gst_in_percentage;
                    this.user_role=JSON.parse(localStorage.getItem("userdetails")).user_role;
                }
               
           
                let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;

                const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
                myHeaders.append('', api_key);
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();

                urlencoded.append("tenant_id", tenant_id);
                urlencoded.append("membership_type_id", this.$route.query.id);
                urlencoded.append("user_role", this.user_role);
                urlencoded.append("gst_percentage", this.gst);
                urlencoded.append("user_id", this.user_id);
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow",
                };

                const rawResponse = await fetch(
                    process.env.VUE_APP_BASE_URL + "membership/getMasterMembershipTypeById",
                    requestOptions
                );

                const response = await rawResponse.json();
                this.cartData = response.data;
                this.amountDetails = response.data[0];
                this.table_details = response.data[0]
                this.extra_amount = this.amountDetails.extra_amount;

            }
        },
      
        async getTenantDetailsById() {
          
       
                var myHeaders = new Headers();
                let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                myHeaders.append('', api_key);
                var urlencoded = new URLSearchParams();
                urlencoded.append("tenant_id", tenant_id);
                urlencoded.append("user_type", "public_user");
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };
                const rawResponse = await fetch(

                    process.env.VUE_APP_BASE_URL + "app/getTenantDetailsById",
                    requestOptions
                );
                const response = await rawResponse.json();
                this.tenant_details = response.data[0]

                this.image_url = this.tenant_details.company_logo

                this.tenant_address = this.tenant_details.address
                
            
                this.tenant_name = this.tenant_details.company_name
                this.tenant_term = this.tenant_details.invoice_terms_and_conditions

                this.address = this.tenant_details.address,
                    this.unit_no = this.tenant_details.unit_no,
                    this.street_name = this.tenant_details.street_name,
                    this.street_number = this.tenant_details.street_number,
                    this.suburb = this.tenant_details.suburb,
                    this.country = this.tenant_details.country,
                    this.city = this.tenant_details.city,
                    this.state = this.tenant_details.state,
                    this.pin_code = this.tenant_details.pin_code
                this.organisation_telephone = this.tenant_details.organisation_telephone,
                    this.organisation_mobile_phone = this.tenant_details.organisation_mobile_phone
             
                this.stripe_account = this.tenant_details.stripe_account_id
            
        },

        // Getting the current financial year.
        getCurrentFinancialYear() {
            var fiscalyear = "";
            var today = new Date();
            if (today.getMonth() + 1 <= 3) {
                fiscalyear = today.getFullYear() - 1 + "-" + today.getFullYear();
            } else {
                fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1);
            }
            return fiscalyear;
        },

        async getUserDetailsByID() {
            // this.isLoading = true;
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
            const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;

            if (tenant_id == null) {
                this.gettenantDomain();
            } else {
                var myHeaders = new Headers();
                const userData = localStorage.getItem("userdetails")

                if (userData == null || undefined) {
                    this.user_id = null
                } else {
                    this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                }
                let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                myHeaders.append('', api_key);

                var urlencoded = new URLSearchParams();

                urlencoded.append("tenant_id", tenant_id);
                urlencoded.append("user_id", this.user_id);
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow",
                };

                const rawResponse = await fetch(
                    process.env.VUE_APP_BASE_URL + "user/getUserDetailsById",
                    requestOptions
                );

                const response = await rawResponse.json();

                this.userData = response.data[0];
                this.userData1 = response.data[1];

                this.$store.commit("app/SET_USER_ROLE", response.data[0].user_role);

                this.isAmount = false
                this.isGst = false
                localStorage.setItem("role", this.userData.user_role)
                this.date_issued = this.userData.created_on
                this.due_date = this.userData.created_on
                this.isLoading = false;
                this.address_id = this.userData.address_id;
                this.address_id2 = this.userData1.address_id;

                this.title = this.userData.title;
                this.first_name = this.userData.first_name;
                this.full_name = this.userData.full_name;
                this.last_name = this.userData.last_name;
                this.dob = this.userData.date_of_birth;
                this.gender = this.userData.gender;
                this.stripe_customer_id = this.userData.stripe_customer_id;

                this.email = this.userData.email,
                    this.mobile_phone = this.userData.mobile_phone;
                this.work_phone = this.userData.work_phone;
                this.company = this.userData.company,
                    this.mainAddressFormat = this.userData.address,
                    this.shippingAddressFormat = this.userData1.address,
                    this.main_unit_no = this.userData.unit_no,
                    this.shiiping_unitNo = this.userData1.unit_no,
                    this.main_address_streetname = this.userData.street_name,
                    this.shipping_address_streetname = this.userData1.street_name,
                    this.main_address_streetno = this.userData.street_number,
                    this.shipping_address_streetno = this.userData1.street_number,
                    this.main_address_suburb = this.userData.suburb,
                    this.main_address_country_name = this.userData.country,
                    this.main_address_city_name = this.userData.city,
                    this.main_address_state_name = this.userData.state,
                    this.main_address_pin_code = this.userData.pin_code,
                    this.shipping_address_suburb = this.userData1.suburb,
                    this.shipping_address_country_name = this.userData1.country,
                    this.shipping_address_city_name = this.userData1.city,
                    this.shipping_address_state_name = this.userData1.state,
                    this.shipping_address_pin_code = this.userData1.pin_code;
                // this.customerListsources(this.userData.stripe_customer_id)

            }
        },

        async getTenantDetailsById() {
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
            const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;

            if (tenant_id == null) {
                this.gettenantDomain();
            } else {

                var myHeaders = new Headers();
                let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                myHeaders.append('', api_key);
                var urlencoded = new URLSearchParams();
                urlencoded.append("tenant_id", tenant_id);
                urlencoded.append("user_type", "public_user");
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };
                const rawResponse = await fetch(

                    process.env.VUE_APP_BASE_URL + "app/getTenantDetailsById",
                    requestOptions
                );
                const response = await rawResponse.json();

                this.tenant = response.data[0]

                this.tenant_work = this.tenant.organisation_mobile_phone
                this.tenant_name = this.tenant.company_name

                this.organisation_telephone = this.tenant.organisation_telephone,
                    this.organisation_mobile_phone = this.tenant.organisation_mobile_phone
                this.image_url = this.tenant.company_logo
                this.tenant_term = this.tenant.invoice_terms_and_conditions

                this.tenant_address = this.tenant.address,
                    this.tenant_unit_no = this.tenant.unit_no,
                    this.tenant_street_name = this.tenant.street_name,
                    this.tenant_street_number = this.tenant.street_number,
                    this.tenant_suburb = this.tenant.suburb,
                    this.tenant_country = this.tenant.country,
                    this.tenant_city = this.tenant.city,
                    this.tenant_state = this.tenant.state,
                    this.tenant_pin_code = this.tenant.pin_code
                this.organisation_telephone = this.tenant.organisation_telephone,
                    this.organisation_mobile_phone = this.tenant.organisation_mobile_phone

                this.gst_percentage = this.tenant.gst_in_percentage
            }
        },
        async submit() {

            if (this.paymentMethod == "newcard") {
                this.$refs.elementsRef.submit();
            } else {
                this.card_id = this.paymentMethod;
                this.charge = {
                    source: "",
                    amount: this.total_amount,
                    description: this.description,
                    stripeAccount: this.stripe_account
                };
                this.sendTokenToServer(this.charge);
            }
        },
        tokenCreated(token) {

            this.charge = {
                card: token.card.id,
                source: token.id,
                amount: this.total_amount,
                description: this.description,
                stripeAccount: this.stripe_account
            };
            this.sendTokenToServer(this.charge);
        },

        async sendTokenToServer(charge) {

            this.isLoading = true;
            const currentDate = new Date();

            const day = currentDate.getDate().toString().padStart(2, '0');
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const year = currentDate.getFullYear().toString();

            const hours = currentDate.getHours().toString().padStart(2, '0');
            const minutes = currentDate.getMinutes().toString().padStart(2, '0');
            const seconds = currentDate.getSeconds().toString().padStart(2, '0');

            this.payment_date = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            let item_description = [];
            if (this.table_details.is_discount_price == 1) {
                var amount = this.table_details.membership_discount_price_total
                var unit_price = this.table_details.membership_discount_price
                var gst = this.table_details.membership_discount_price_gst_amonut
            } else {
                var amount = this.table_details.member_price_total
                var unit_price = this.table_details.membership_price
                var gst = this.table_details.membership_price_gst_amonut
            }
            var item = {
                membership: this.table_details.membership_type_name,
                code: this.table_details.membership_type_code,
                description: this.table_details.membership_type_name,
                amount: amount,
                item_qty: 1,
                unit_price: unit_price,
                gst: gst,
                discount_amount: this.amountDetails.extra_amount == 1 ? this.amountDetails.consumed_amount : 0

            }
            item_description.push(item)

            var myHeaders = new Headers();
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                this.user_role=JSON.parse(localStorage.getItem("userdetails")).gst_in_percentage;
                this.token=JSON.parse(localStorage.getItem("userdetails")).jwttoken;
            }
          
         

            myHeaders.append('x-access-token', this.token);

            const formData = new FormData();
            let crm_id = localStorage.getItem("crm_id");

            formData.append("tenant_id", tenant_id);
            formData.append("user_id", this.user_id);

            formData.append(
                "membership_type_code",
                this.amountDetails.membership_type_code
            );

            formData.append("status", "active");
            formData.append("financial_year", this.getCurrentFinancialYear());
            formData.append("approval_status", "approved");
            formData.append("new_upgrade_membership", 1);
            if (this.amountDetails.extra_amount == 0) {
                formData.append(
                    "actual_amount",
                    this.amountDetails.is_discount_price == 1 ?
                    this.amountDetails.membership_discount_price :
                    this.amountDetails.membership_price
                );

            } else if (this.amountDetails.extra_amount == 1) {
                formData.append("actual_amount", this.amountDetails.toatl_price_with_discount);
            }
            formData.append(
                "total_amount",
                this.amountDetails.is_discount_price == 1 ?
                this.amountDetails.membership_discount_price :
                this.amountDetails.membership_price
            );
            if (this.amountDetails.extra_amount == 0) {
                formData.append(
                    "total_gst_amount",
                    this.amountDetails.is_discount_price == 1 ?
                    this.amountDetails.membership_discount_price_gst_amonut :
                    this.amountDetails.membership_price_gst_amonut
                );
            } else if (this.amountDetails.extra_amount == 1) {
                formData.append(
                    "total_gst_amount",
                    this.amountDetails.gst_payment_amount
                );
            }

            formData.append("total_discount", this.amountDetails.extra_amount == 1 ? this.amountDetails.consumed_amount : 0);

            formData.append("comments", this.Comments);

            formData.append("user_role", this.user_role);

            formData.append("additional_dis_amnt", this.amountDetails.extra_amount == 1 ? this.amountDetails.consumed_amount : 0);
            formData.append("promo_id", 0);
            formData.append("payment_method", this.paymentMethod);
            formData.append("payment_status", this.paymentMethod == "invoice" ? 0 : "1");

            if (this.amountDetails.extra_amount == 0) {
                formData.append(
                    "paid_amount",
                    this.amountDetails.is_discount_price == 1 ?
                    this.amountDetails.membership_discount_price_total :
                    this.amountDetails.member_price_total
                );

            } else if (this.amountDetails.extra_amount == 1) {
                formData.append("paid_amount", this.amountDetails.payment_amount);
            }

            formData.append("crm_user_id", this.user_id);
            formData.append("order_id", 0);

            formData.append("email", this.email);
            formData.append("full_name", this.full_name);
            formData.append("stripe_customer_id", this.stripe_customer_id);
            formData.append("description", this.amountDetails.membership_type_code + '\xa0' + 'membership');
            formData.append("token_from_stripe", charge.source);
            formData.append("card_id", charge.card);

            formData.append("company_logo", this.image_url);
            formData.append("invoice_terms_and_conditions", this.tenant_term);

            formData.append("membership_type", this.amountDetails.membership_type);
            formData.append("order_for_id", this.amountDetails.membership_type_id);
            formData.append("order_for", "membership");
            formData.append("item_description", JSON.stringify(item_description));

            formData.append("membership_term", this.amountDetails.membership_term);
            formData.append("member_status", 1);

            formData.append("organisation_name", this.tenant_name);
            formData.append("payment_date", this.payment_date);

            formData.append("organisation_mobile_phone", this.organisation_mobile_phone);
            formData.append("organisation_telephone", this.organisation_telephone == null ? '' : this.organisation_telephone);
            formData.append(
                "tenant_address_list",
                JSON.stringify([{

                    address: this.tenant_address,
                    unit_no: this.tenant_unit_no,
                    street_name: this.tenant_street_name,
                    street_number: this.tenant_street_number,
                    suburb: this.tenant_suburb,
                    country: this.tenant_country,
                    city: this.tenant_city,
                    state: this.tenant_state,
                    pin_code: this.tenant_pin_code,
                    address_type: 1,
                    main_address_type: 1,

                }])
            );
            formData.append(
                "address_list",
                JSON.stringify([{

                        address: this.client_address_main,
                        unit_no: this.client_unit_no_main,
                        street_name: this.client_street_name_main,
                        street_number: this.client_street_number_main,
                        suburb: this.client_suburb_main,
                        country: this.client_country_main,
                        city: this.client_city_main,
                        state: this.client_state_main,
                        pin_code: this.client_pin_code_main,
                        address_type: 1,
                        main_address_type: 1,
                    },
                    {
                        address: this.client_address_ship,
                        unit_no: this.client_unit_no_ship,
                        street_name: this.client_street_name_ship,
                        street_number: this.client_street_number_ship,
                        suburb: this.client_suburb_ship,
                        country: this.client_country_ship,
                        city: this.client_city_ship,
                        state: this.client_state_ship,
                        pin_code: this.client_pin_code_ship,
                        address_type: 1,
                        main_address_type: 0,
                    }
                ])
            );

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formData,
                redirect: "follow",
            };

            const rawResponse = await fetch(
                process.env.VUE_APP_BASE_URL + "membership/crmMembershipPlaceOrder",

                requestOptions
            );

            const response = await rawResponse.json();
            if (response.status == 0) {

                this.isLoading = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.message,
                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                setTimeout(() => this.$router.push("/index"), 2000);
                return false;

            } else {

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Order placed successfully',
                        icon: "CheckIcon",
                        variant: "success",
                    },
                });

                this.isLoading = false;
                setTimeout(() => this.$router.push("/index"), 2000);
                return false

            }

        },

    },
    setup() {
        const refFormWizard = ref(null)
        const formWizardNextStep = () => {
            refFormWizard.value.nextTab()
        }

        // ? This is just dummy details
        const checkoutDetails = ref({
            address: {
                fullName: '',
                mobile: '',
                houseNo: '',
                landmark: '',
                city: '',
                pincode: '',
                state: '',
                addressType: null,
            },
            payment: {
                cvv: '',
            },
        })

        return {
            refFormWizard,
            formWizardNextStep,

            // Dummy Details
            checkoutDetails,
            avatarText,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import "@core/scss/vue/libs/vue-select.scss";
</style><style lang="scss">
.checkout-form-wizard ::v-deep {
    .wizard-tab-content {
        box-shadow: none !important;
        background: transparent !important;
        padding: 0;
    }
}

.list-view .ecommerce-card {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    // grid-template-columns:2fr 1fr;
}

.list-view .ecommerce-card .item-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.list-view .ecommerce-card .card-body {
    border-right: 1px solid rgba(60, 60, 60, 0.26);
}

.list-view .ecommerce-card .card-body {
    padding: 1.5rem 1rem;
}

.list-view .ecommerce-card .card-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.list-view .ecommerce-card .item-options {
    padding: 1rem;
}

.list-view .ecommerce-card .item-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.list-view {
    display: grid;
    grid-template-columns: 1fr;
}

.ecommerce-card:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
    box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}

.ecommerce-card .item-name a {
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.checkout-items .ecommerce-card .item-company {
    margin-bottom: .4rem !important;
}

.list-view .ecommerce-card .item-company {
    margin: .3rem 0 .5rem;
}

.list-view .ecommerce-card .item-company {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-weight: 400;
    font-size: .875rem;
}

.item-company .company-name {
    margin-left: .25rem;
}

.list-view .ecommerce-card .item-company .company-name {
    font-weight: 600;
}

.checkout-items .ecommerce-card .item-rating {
    margin-bottom: .4rem !important;
}

.ecommerce-card .item-rating ul {
    margin-bottom: 0;
}

.checkout-items .ecommerce-card .item-quantity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.checkout-items .ecommerce-card .delivery-date {
    margin-top: 1.2rem;
    margin-bottom: .25rem;
}

.checkout-options .price-details .price-title {
    margin-bottom: 0.75rem;
    margin-top: 1.5rem;
    font-weight: 600;
}

.checkout-options .price-details .price-detail {
    margin-bottom: 0.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.checkout-options .coupons input {
    padding-left: 0;
    padding-right: 0;
    border: none;
}

.checkout-options .coupons input {
    color: #6e6b7b !important;
    font-weight: 600;

}

.checkout-options .price-details .price-detail {
    margin-bottom: .75rem;
}

.checkout-options .price-details .price-detail .detail-title.detail-total {
    font-weight: 600;
}

.input-group:not(.bootstrap-touchspin):focus-within .form-control {
    box-shadow: none !important;
}

.checkout-options .coupons .input-group-append {
    margin: 0;
}

.checkout-options .coupons .input-group-text {
    padding: inherit;
}

.checkout-options .coupons .input-group-text {
    height: auto;
    font-weight: 600;
    border: none;
}

.input-group:not(.bootstrap-touchspin):focus-within {
    box-shadow: none !important;
}

.checkout-items .ecommerce-card .item-options .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.list-view .ecommerce-card .item-options .item-wrapper {
    position: relative;
}

.item-options .item-wrapper .item-cost .item-price {
    margin-bottom: 0;
}

.list-view .ecommerce-card .item-options .item-wrapper .item-cost .item-price {
    color: #3369f2 !important;
}

.list-view .ecommerce-card .item-options .shipping {
    margin-top: 0.75rem;
}

.list-view .ecommerce-card .item-options .btn-cart {
    margin-top: 1rem;
}

div.input-group {
    padding: 0 !important;
}

.ecommerce-card .item-img img {
    width: 100px;
}

@media (min-width: 992px) {
    .product-checkout.list-view {
        grid-template-columns: 2fr 1fr;
        -webkit-column-gap: 2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
    }
}

@media (max-width: 767.98px) {
    .list-view .ecommerce-card {
        grid-template-columns: 1fr;
    }

    .list-view .ecommerce-card .item-img {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .list-view .ecommerce-card .card-body {
        border: none;
    }
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.loader {
    color: #ffffff;
    /* Customize the color of the spinner */
}
</style><style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
    .wizard-tab-content {
        box-shadow: none !important;
        background: transparent !important;
        padding: 0;
    }
}

// .image {
//     width: 200px;

// }

.search-location {
    display: block;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 400;
    outline: none;
    height: 45px;
    //   width:700px;
    width: 100%;

    line-height: 30px;
    text-align: center;
}

.pac-container {
    z-index: 10000 !important;
}

#card {
    width: 550px;

}

// #footer {
//     height: 200px;
// }
</style>
